import { Actions, thunk } from 'easy-peasy';
import api from '~/services/api';
import { IStoreModel } from '~/store/types';
import { IClientAllocationActions, IThunkGetOwnClientAllocation } from '../types';

const onGetOwnClientAllocationGU: IThunkGetOwnClientAllocation = thunk(
  async (
    { setClientAllocation }: Actions<IClientAllocationActions>,
    payload,
    { getStoreState },
  ) => {
    const store = getStoreState() as IStoreModel;

    const profile = store.user.current;

    const clientAllocations = await api.clientAllocation
      .getOwnClientAllocationGU(payload)
      .then(r => r.data);

    if (!clientAllocations.length) {
      return;
    }

    const normalizedAllocations = clientAllocations.map(item => ({
      id: item.id,
      date: item.date,
      isArchived: false,
      isOwn: true,
      user: {
        id: profile.id,
        name: `${profile.firstName} ${profile.lastName}`,
        subRoles: profile.subRoles,
        photo: profile.photo,
        clients: item.clients,
      },
      locationGroups: item.locationGroups,
      team: item.team,
    }));

    setClientAllocation(normalizedAllocations);
  },
);

export default onGetOwnClientAllocationGU;
