import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import classNames from 'classnames';
import { ReactElement, useCallback, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';

import { useStoreActions, useStoreState } from '~/store/hooks';
import Loader from '~/ui/components/common/Loader';
import DateRangePicker from '~/ui/components/inputs/DateRangePicker';
import Select from '~/ui/components/inputs/SelectWithoutAnimation';
import { red } from '~/ui/constants/colors';
import {
  DUPLICATE_CLIENT_ALLOCATION,
  EDIT_CLIENT_ALLOCATION,
  EDIT_MY_CLIENT_ALLOCATION,
  VIEW_CLIENT_ALLOCATION,
} from '~/ui/constants/paths';
import { dateSortingOptions } from '~/ui/constants/sortingOptions';
import EmptyState from '~/ui/pages/Notifications/components/EmptyState';
import { getLocalDateNoFormat, isoToLocal, normalizeDateString } from '~/utils/date/date';
import { extractErrorMessage } from '~/utils/error/error';

import { ReactComponent as EditIcon } from '~/ui/assets/images/edit.svg';
import useRole from '~/store/user/hooks/useRole';
import styles from './ClientAllocation.module.scss';
import { IClientAllocationGroup } from '~/services/api/clientAllocation/types';

interface IProps {
  clinicId: string;
  actTeamId?: string;
  noActions?: boolean;
}

const ClientAllocation = ({ clinicId, actTeamId, noActions }: IProps): ReactElement => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const { isActTeamMember, isGlobalUser } = useRole();

  const { onGetClientAllocations, onGetGlobalUserClientAllocations } = useStoreActions(
    actions => actions.clientAllocation,
  );
  const getAllocations = isGlobalUser ? onGetGlobalUserClientAllocations : onGetClientAllocations;
  const { showError } = useStoreActions(actions => actions.snackbar);
  const {
    list: clientsAllocation,
    pagination: { pageSize, pageNumber, total },
    filters,
  } = useStoreState(state => state.clientAllocation);

  const formMethods = useForm({
    defaultValues: {
      sorting: filters.sorting,
      dateRange:
        filters?.dateRange[0] && filters?.dateRange[1]
          ? [getLocalDateNoFormat(filters.dateRange[0]), getLocalDateNoFormat(filters.dateRange[1])]
          : [],
    },
  });

  const {
    control,
    formState: { errors },
    watch,
  } = formMethods;

  const { dateRange: range, sorting } = watch();

  const [from, to] = range || [];

  const handlePagination = (pSize: number, pNumber: number) =>
    getAllocations({
      clinicId,
      teamId: actTeamId,
      params: {
        pageSize: pSize,
        pageNumber: pNumber,
        sorting,
        from: from ? normalizeDateString(from) : undefined,
        to: to ? normalizeDateString(to, true) : undefined,
      },
    });

  const onMount = useCallback(async () => {
    try {
      if (range.every(item => !item) || range.every(item => !!item)) {
        const payload = {
          clinicId,
          teamId: actTeamId,
          params: {
            pageSize,
            pageNumber,
            sorting,
            from: from ? normalizeDateString(from) : undefined,
            to: to ? normalizeDateString(to, true) : undefined,
          },
        };
        setLoading(true);
        await getAllocations(payload);
      }
    } catch (e) {
      showError(extractErrorMessage(e));
    } finally {
      setLoading(false);
    }
  }, [
    range,
    clinicId,
    actTeamId,
    pageSize,
    pageNumber,
    sorting,
    from,
    to,
    getAllocations,
    showError,
  ]);

  useEffect(() => {
    onMount();
  }, [onMount]);

  const navigateToEdit = (item: IClientAllocationGroup) => {
    const editPath = (isGlobalUser ? EDIT_MY_CLIENT_ALLOCATION : EDIT_CLIENT_ALLOCATION).replace(
      ':clientAllocationDate',
      String(item.date.split('T')[0]),
    );
    if (isGlobalUser) {
      navigate(editPath);
    } else {
      navigate(editPath.replace(':actTeamId', actTeamId));
    }
  };

  if (loading) return <Loader />;

  return (
    <TableContainer className={styles.tableContainer}>
      <Box sx={{ p: 2 }}>
        <FormProvider {...formMethods}>
          <Grid container spacing={1}>
            <Grid item sm={2}>
              <Select
                label="Sort By"
                control={control}
                name="sorting"
                errors={errors}
                options={dateSortingOptions}
                color={red}
                hideSelectedOptions={false}
              />
            </Grid>
            <Grid item sm={4}>
              <DateRangePicker
                label="Select Date Range"
                control={control}
                name="dateRange"
                errors={errors}
              />
            </Grid>
          </Grid>
        </FormProvider>
      </Box>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Date</TableCell>
            {!noActions && <TableCell align="right">Actions</TableCell>}
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {clientsAllocation?.length ? (
            clientsAllocation.map(item => {
              const disabled = (isActTeamMember || isGlobalUser) && !item.containsOwn;
              return (
                <TableRow key={item.date}>
                  <TableCell>
                    <Link
                      to={VIEW_CLIENT_ALLOCATION.replace(':actTeamId', actTeamId).replace(
                        ':clientAllocationDate',
                        String(item.date.split('T')[0]),
                      )}
                      className={styles.linkView}
                    >
                      {isoToLocal(item.date)}
                    </Link>
                  </TableCell>
                  {!noActions && (
                    <>
                      <TableCell align="right">
                        <IconButton
                          disabled={disabled}
                          onClick={() => {
                            navigateToEdit(item);
                          }}
                        >
                          <EditIcon className={disabled ? styles.disabled : ''} />
                        </IconButton>
                      </TableCell>
                      <TableCell>
                        <span
                          role="presentation"
                          onClick={() =>
                            !disabled
                              ? navigate(
                                  DUPLICATE_CLIENT_ALLOCATION.replace(
                                    ':actTeamId',
                                    actTeamId,
                                  ).replace(
                                    ':clientAllocationDate',
                                    String(item.date.split('T')[0]),
                                  ),
                                )
                              : undefined
                          }
                          className={classNames(styles.duplicateButton, {
                            [styles.disabled]: disabled,
                          })}
                        >
                          Duplicate
                        </span>
                      </TableCell>
                    </>
                  )}
                </TableRow>
              );
            })
          ) : (
            <EmptyState />
          )}
        </TableBody>
      </Table>
      <TablePagination
        component="div"
        count={total}
        onPageChange={(_, pNumber) => handlePagination(pageSize, pNumber + 1)}
        onRowsPerPageChange={e => handlePagination(Number(e.target.value), 1)}
        page={pageNumber - 1}
        rowsPerPage={pageSize}
        rowsPerPageOptions={[5, 10, 25]}
      />
    </TableContainer>
  );
};

export default ClientAllocation;
